import { Bold } from './helpers/styling';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { maxWidth } from '../styles/text';
import { lightGreen, white } from '../styles/colors';
import { FontWeights } from '../styles/fonts';
import InlineLink from './inlineLink';

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <InlineLink data={node.data} content={node.content} />
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Description>{children}</Description>
    ),
  },
};

const BlogTitle = ({
  style,
  title,
  description,
  introduction,
  publishDate,
  categories,
  writers,
}) => (
  <Container style={style}>
    <Title>{title}</Title>
    <Description>{description}</Description>
    <p>{publishDate}</p>
    <Fragment>{introduction && renderRichText(introduction, options)}</Fragment>
    {categories && categories.length > 0 && (
      <p>
        {categories.map((item, index) => {
          return (
            <Category id={item.id} key={'category-' + index}>
              {item.name}
            </Category>
          );
        })}
      </p>
    )}
    {writers && writers.length > 0 && (
      <Writers>
        {writers.map((item, index) => (
          <Writer id={item.id} key={'writer-' + index}>
            {item.name}
          </Writer>
        ))}
      </Writers>
    )}
  </Container>
);

BlogTitle.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  style: PropTypes.object,
};
BlogTitle.defaultProps = {
  title: '',
  description: '',
  style: {},
};

export default BlogTitle;

const Container = styled.section`
  background-color: ${white};
  display: flex;
  padding: 5rem 6vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// TODO: same as title?
const Title = styled.h1`
  display: block;
  margin: 0 auto;
  max-width: ${maxWidth}px;
  font-size: 2rem;
  font-weight: ${FontWeights.montSerrat.normal};
  color: ${lightGreen};
  padding: 0 1rem 0 1rem;
  position: relative;
`;

const Description = styled.article`
  display: flex;
  margin: 0 auto;
`;

const Category = styled.span`
  margin: 0 auto;
`;

const Writers = styled.div`
  display: flex;
  flex-direction: column;
`;
const Writer = styled.div`
  margin: 0 auto;
`;
