import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Meta } from '../components/seo';
import Paragraph from '../components/paragraph';
import PropTypes from 'prop-types';
import Quote from '../components/quote';
import Contact from '../components/contact';
import Divider from '../components/divider';
import Timeline from '../components/timeline';
import FullWidthImage from '../components/fullWidthImage';
import ContactForm from '../components/contactForm';
import BlogTitle from '../components/blogTitle';

function parseSlugIds(slugIds) {
  // TODO: useMemo?
  return slugIds.allContentfulPage.edges.reduce((a, b) => {
    const reference = a.node !== undefined ? { [a.node.id]: a.node.slug } : a;
    reference[b.node.id] = b.node.slug;
    return reference;
  });
}

function createContent(item, slugIds, formApiURL) {
  // TODO: create one function to be used in page & blog?
  switch (item.type) {
    case 'paragraph':
      return (
        <Paragraph
          key={`p${item.id.substring(0, 8)}`}
          content={item}
          slugIds={slugIds}
        />
      );
    case 'quote':
      return (
        <Quote
          key={`q${item.id.substring(0, 8)}`}
          title={item.quoteTitle}
          person={item.quotePerson}
          type={item.quoteType}
          hideQuoteCharacters={item.hideQuoteCharacters}
          message={item.quote}
        />
      );
    case 'contact':
      return (
        <Contact
          key={`c${item.id.substring(0, 8)}`}
          message={item.message}
          backgroundImage={item.backgroundImage}
          tagline={item.tagline}
        />
      );
    case 'divider':
      return (
        <Divider key={`d${item.id.substring(0, 8)}`} type={item.dividerType} />
      );
    case 'timeline':
      return (
        <Timeline
          key={`ti${item.id.substring(0, 8)}`}
          title={item.title}
          description={item.description}
          entries={item.entries}
        />
      );
    case 'fullwidthimage':
      return (
        <FullWidthImage
          key={`fwi${item.id.substring(0, 8)}`}
          image={item.image}
        />
      );
    case 'contactform':
      return (
        <ContactForm
          key={`cf${item.id.substring(0, 8)}`}
          url={formApiURL}
          introduction={item.introduction}
          backgroundImage={item.backgroundImage}
          formSendText={item.formSendText}
          formFields={item.formFields}
        />
      );
    default:
      return null;
  }
}

// TODO: import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; see https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer => skip to import { renderRichText } from 'gatsby-source-contentful/rich-text'; ?
// TODO: staticQuery not used? while provided in props only?
const Blog = ({ data, ...props }) => (
  <Layout
    showAnkeiler={props.pageContext.showAnkeiler}
    hasHeader={props.pageContext.headerReference}
  >
    <BlogTitle
      title={props.pageContext.title}
      description={props.pageContext.description}
      publishDate={props.pageContext.publishDate}
      writers={props.pageContext.writers}
      categories={props.pageContext.categories}
    />
    {/* TODO: create title, description & show dates */}
    {props.pageContext.content.map((item) =>
      createContent(item, parseSlugIds(data), data.site.siteMetadata.formApiURL)
    )}
  </Layout>
);
Blog.propTypes = {
  pageContext: PropTypes.object,
};
Blog.defaultProps = {
  pageContext: {},
};

export default Blog;

export const Head = (props) => <Meta {...props} />;

export const query = graphql`
  {
    allContentfulParagraph(
      filter: { title: { title: { eq: "Privacy statement" } } }
    ) {
      edges {
        node {
          id
          title {
            title
          }
          content {
            raw
          }
        }
      }
    }
    allContentfulPage {
      edges {
        node {
          __typename
          id
          description
          slug
        }
      }
    }
    site {
      siteMetadata {
        formApiURL
        ...SiteMetaData
      }
    }
  }
`;
